import React from "react";
import NavTop from "../Navigation/NavTop";

const App = () => (
  <div>
    <NavTop />
    <h1>Welcome to the markdown web editor</h1>
    <h3>Please sign up to use it</h3>
  </div>
);
export default App;
